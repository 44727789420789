import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import 'constants/sass/index.sass'
import { TARGET_PLATFORM, API_BASE_URL } from 'constants/config'
import store from '../store'

const rootElement = document.getElementById('root-landing')

ReactDOM.render(
  <Provider store={store}>
    <h1>Current Platform: {TARGET_PLATFORM}</h1>
    <ul>
      <li><a href="/me.html">Accès patient</a></li>
    </ul>
    { TARGET_PLATFORM == 'charles' &&
      (
        <ul>
          <li><a href="/academy.html?slug=time-program">Achat TIME</a></li>
          <li><a href="/academy.html?slug=master-program">Achat MASTER</a></li>
          <li><a href="/academy.html?slug=sommeil-program">Achat SOMMEIL</a></li>
          <li><a href="/onboarding.html?spe=premature_ejaculation&refresh=true">Questionnaire EP</a></li>
          <li><a href="/onboarding.html?spe=erectile_dysfunction&refresh=true">Questionnaire DE</a></li>
          <li><a href="/onboarding.html?spe=erectile_dysfunction&refresh=true&securite_sociale=1">Questionnaire DE - remboursement</a></li>
          <li><a href="/onboarding.html?spe=fertility&refresh=true">Questionnaire Fertilité</a></li>
          <li><a href="/onboarding.html?spe=andropause&refresh=true">Questionnaire ANP</a></li>
          <li><a href="/onboarding.html?spe=libido&refresh=true">Questionnaire Libido</a></li>
          <li><a href="/onboarding.html?spe=other&refresh=true">Questionnaire Autre</a></li>
          <li><a href="/onboarding.html?spe=sleep&refresh=true">Questionnaire Sommeil</a></li>
          <li><a href="/onboarding.html?spe=hair&refresh=true">Questionnaire Cheveux</a></li>
          <li><a href="/onboarding.html?spe=digestion&refresh=true">Questionnaire Digestion</a></li>
          <li><a href="/onboarding.html?spe=weight&refresh=true">Questionnaire Poids</a></li>
          <li><a href="/onboarding.html?spe=charles_couple_therapy&refresh=true">Couple therapy Homme</a></li>
          <li><a href="/onboarding.html?spe=charles_psycho_sexo&refresh=true">Psycho Sexo Homme</a></li>
        </ul>
      )
    }
    { TARGET_PLATFORM == 'mia' &&
      (
        <ul>
          <li><a href="/academy.html?slug=vaginisme-program">Achat VAGINISME</a></li>
          <li><a href="/onboarding.html?spe=couple_therapy&refresh=true">Couple</a></li>
          <li><a href="/onboarding.html?spe=reach_orgasm&refresh=true">Difficulté à atteindre l'orgasme</a></li>
          <li><a href="/onboarding.html?spe=sexual_desire&refresh=true">Baisse du désir sexuel</a></li>
          <li><a href="/onboarding.html?spe=sexual_wellness&refresh=true">Bien-être sexuel</a></li>
          <li><a href="/onboarding.html?spe=pain&refresh=true">Douleur à la pénétration</a></li>
          <li><a href="/onboarding.html?spe=weight&refresh=true">Questionnaire Poids</a></li>
          <li><a href="/onboarding.html?spe=mia_other&refresh=true">Autre</a></li>
        </ul>
      )
    }
  </Provider>,
  rootElement,
)
